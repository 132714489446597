import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LogoutModal from './support/LogoutModal';
import helpIcon from "../../../src/assets/help.svg";
import axios from 'axios';
import { GetUrl } from '../../config/GetUrl';
import MainLoader from '../../components/loader/MainLoader';
import { toast } from 'react-toastify';

const RegisterAccount = () => {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    };

    const handleOnboarding = async () => {
      setLoading(true);
      try {
        const response = await fetch( GetUrl.API_URL+'/retailer/createRetailerAccount', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Ensure JSON format
            Authorization: `Bearer ${localStorage.getItem('token')}`,  // Add the token in the Authorization header
        },
        });

        const data = await response.json(); // Parse the response JSON
    console.log(data, "Parsed Response"); // Log the parsed response

    if (data?.url) {
      window.location.href = data.url; // Redirect if URL is available
    } else {
      toast.success(data?.message);
    }
       
      } catch (error) {
        console.error('Error creating onboarding link:', error);
        toast.error(error);
      } finally {
        setLoading(false);
      }
    };

    return (
        <>
          {loading && <MainLoader/>}
            <LogoutModal show={show} handleClose={handleClose} />
          
          
                <div onClick={handleOnboarding} className="mt-3 text-decoration-none "
    style={{
      // fontWeight: "bold",
      // fontSize: "1.1rem",
      color: "#C73618", // Tomato color for design
      // display: "inline-block",
      // padding: "0.5rem",
      borderRadius: "0.375rem",
      transition: "color 0.3s, text-decoration 0.3s",
    }}
    >
                    {/* <div className="h5 mb-0 "> */}
                    <i className="fas fa-credit-card me-2"></i>Connect Stripe 
                    {/* </div> */}
                </div>
         
{/* 
<div>
      <h1>Retailer Onboarding</h1>
      <button onClick={handleOnboarding} disabled={loading}>
        {loading ? 'Redirecting to Stripe...' : 'Start Onboarding'}
      </button>
    </div> */}
        </>
    );
};

export default RegisterAccount;
