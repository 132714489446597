import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import helpIcon from "../../../assets/help.svg"
import logoutICon from "../../../assets/logout.svg"
import rightArrow from "../../../assets/arrow-right.svg"
import LogoutModal from './LogoutModal'

const Support = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false)

    const logoutHandler = () => {

        setShow(true)
    }
    const handleClose = () => {
        setShow(false)
    }

    return (
        <>
            {/* <p className="my-3 text-uppercase text-decoration-underline">Support</p> */}
            <LogoutModal show={show} handleClose={handleClose} />
            <Link to={'/settings/help-and-support'} className="border-0 p-0  list-group-item ">
                {/* <div className="text-end"> */}
                <div className="d-inline-block text-decoration-none "
                    style={{
                        //   fontWeight: "bold",
                        //   fontSize: "1.1rem",
                        color: "#C73618", // Tomato color for design
                        // display: "inline-block",
                        // padding: "0.5rem",
                        borderRadius: "0.375rem",
                        transition: "color 0.3s, text-decoration 0.3s",
                    }}
                // onMouseEnter={(e) => {
                //   e.target.style.color = "#dc3545"; // Red color on hover
                //   e.target.style.textDecoration = "underline";
                // }}
                // onMouseLeave={(e) => {
                //   e.target.style.color = "#ff6347"; // Tomato color when not hovered
                //   e.target.style.textDecoration = "none";
                // }}
                >
                    {/* <img src={helpIcon} alt="help" className="img-fluid me-3" />  */}
                    <i className="fas fa-life-ring me-2"></i> Help Support
                </div>
                {/* </div> */}
                {/* <span className="">
                    <img src={rightArrow} alt="arrowright" className="img-fluid arrow-right" />
                </span> */}
            </Link>

            {/* <a onClick={logoutHandler} className="border-0 p-0 mb-4 list-group-item d-flex justify-content-between align-items-center">
                <div className="me-auto">
                    <div className="h5 mb-0 d-flex align-items-end setting-images" >
                        <img src={logoutICon} alt="logout" className="img-fluid me-3" /> Log Out
                    </div>
                </div>
            </a> */}
        </>
    )
}

export default Support