import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../../assets/logo.png";
import buttomLine from "../../assets/line.svg";
import passwordShow from "../../assets/password-show.svg";
import showPassIcon from "../../assets/password-show-icon.svg";
import Background from "../../assets/bg-image2.jpg"
import { FormValidation, FormValidationOnChange } from "./FormValidation";
import { useLoginPostMutation } from "../../Services/LoginApi";
import { toast } from "react-toastify";
import { doLogin } from "../../auth/index";
import LoadingAction from "../loader/LoadingAction";
import { useSelector } from "react-redux";

//import { messaging, getToken, onMessage  } from "../../firebase";


const Login = () => {
  const navigate = useNavigate();
  const [addLoginFormData] = useLoginPostMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [inputField, setInputField] = useState({
    email: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [errField, setErrField] = useState({
    emailErr: "",
    passwordErr: "",
  });
  const fcm_token = useSelector((state) => state.createProfileSlice.fcm_token);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };
  const submitHandler = (e) => {
    e.preventDefault();

    if (FormValidation({ inputField, setErrField })) {
      inputField["role"] = "retailer";
      inputField["fcm_token"] = fcm_token;
      setShowLoader(true);
      addLoginFormData(inputField)
        .unwrap()
        .then((res) => {
          setShowLoader(false);
          if (res?.success) {
console.log(res,"==>>>>>>>>login")
if (!res?.data?.isVerifiedByAdmin) {  
  toast.error("Your account has not been verified by the admin.");
} else if (!res?.data?.is_deactivated) {
  toast.error("Your account has been deactivated.");
} else {
  doLogin(res?.data?.token, res?.data?.fullName, res?.data?.email);
  toast.success(res?.data?.message);
  navigate("/dashboard");
}
            // if (res?.data?.isVerifiedByAdmin || res.data.is_deactivated ) {
            //   console.log(res?.data?.isVerifiedByAdmin,"res?.data?.isVerifiedByAdmin")
            //   doLogin(res?.data?.token, res?.data?.fullName, res?.data?.email);
            //   toast.success(res?.message);
            //   // navigate("/dashboard");
            // } else {
            //   // navigate("/login");
            //   if(res?.data?.isVerifiedByAdmin==false){
            //     toast.error("Your account has not been verified by admin.");
            //   }else{
            //   toast.error("Your account has been deactivated.");
            //   }
            // }

           
          }
        })
        .catch((err) => {
          setShowLoader(false);
          toast.error(err?.data?.message);
        });
    }

    if (rememberMe) {
      document.cookie = `rememberMe=${inputField.email}:${inputField.password}; max-age=3600`;
    }
  };

  const inputChangeHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    let inputName = e.target.name;
    let inputValue = e?.target?.value;
    FormValidationOnChange({ inputValue, setErrField, inputName });
  };

  useEffect(() => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");
    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === "rememberMe") {
        const [savedUsername, savedPassword] = value.split(":");
        setInputField({
          email: savedUsername,
          password: savedPassword,
        });
        setRememberMe(true);
      }
    }
    console.log("Hello");
  }, []);






  return (
    <div className="section bg-white">
      <div className="height-100">
        {/* <div className="top">
          <div className="container-fluid px-md-100 mt-5">
            <div className="row">
              <div className="col-md-12">
                <Link to={"/register"}>
                  <img src={backArrow} alt="arrow" className="img-fluid" />
                </Link>
                <h4 className="design-color fw-bold mt-4">Log in</h4>
              </div>
            </div>
          </div>
        </div> */}
        {showLoader && <LoadingAction />}
        <div className="middle">
          <div className="container-fluid h-100 mx-0 px-0">
            <div className="row h-100 align-items-center justify-content-center mx-0 px-0">



              <div className="col-xl-5 col-lg-5 col-md-10 register-gap px-lg-4 px-md-0">


                <div className="top mx-xl-5 mx-lg-4 mx-md-5">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 mb-4 px-0">
                        <Link to={'/login'}><img src={backArrow} alt="arrow" className="img-fluid logo-size" /></Link>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-xl-5 mx-lg-4 mx-md-5">

                  <h3 className="design-color fw-bolder mt-lg-5 mt-md-4 mb-0">Login</h3>
                  <p className='mb-5 text-muted'>Welcome to Dealshaq Retailer</p>
                  <form action="" className="form-login login">
                    <div className="mb-4">
                      <label className='form-label'>Email</label>
                      <input
                        type="email"
                        className="form-control py-3"
                        placeholder="Enter Email Address"
                        aria-label="email"
                        aria-describedby="email"
                        name="email"
                        value={inputField.email}
                        onChange={inputChangeHandler}
                      />
                      {errField?.emailErr?.length > 0 && (
                        <span className="error">{errField?.emailErr}</span>
                      )}
                    </div>

                    <div className="position-relative">
                      <label className='form-label'>Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control py-3 position-relative"
                        placeholder="Enter Password"
                        aria-label="password"
                        aria-describedby="password"
                        name="password"
                        value={inputField.password}
                        onChange={inputChangeHandler}
                      />
                      <img
                        src={showPassword ? showPassIcon : passwordShow}
                        alt="password-show"
                        className="img-fluid position-absolute password-show"
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    {errField?.passwordErr?.length > 0 && (
                      <span className="error">{errField?.passwordErr}</span>
                    )}




                    <div className="clearfix">
                      <div className="float-start">

                        <div className="mt-4 form-check text-muted">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberMe"
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                          />
                          <label
                            className="form-check-label text-muted"
                            for="exampleCheck1"
                          >
                            Remember me
                          </label>
                        </div>


                      </div>
                      <div className="float-end">


                        <h6 className="mt-4 fw-normal">
                          {/* <span className="text-black">Forgot Password?</span> */}
                          <Link to={"/forget-password"} className="design-color text-decoration-none mt-2">
                            Forgot Password?
                          </Link>
                        </h6>

                      </div>
                    </div>


                    <div className="col-md-10 mx-auto">
                      <div className='text-center'>


                        <button
                          type="submit"
                          className="btn bg-button w-100 rounded-pill text-white py-3 mt-md-5 mt-lg-3 mt-xl-5 mt-3"
                          onClick={submitHandler}
                        >
                          Log in
                        </button>
                      </div>
                    </div>



                    <h6 className="text-center mt-4">
                      <span className="text-black me-2">Don't have an Account?</span>
                      <Link
                        to={"/register"}
                        className="design-color fw-bold text-decoration-none"
                      >
                        Sign up
                      </Link>
                    </h6>
                  </form>


                </div>
              </div>

              <div className="col-lg-7 col-md-12 h-100 hidden-mobile p-0">

                <div className=' h-100'>
                  <img src={Background} className="img-fluid h-100 object-fit-cover w-100 rounded-0" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="bottom">
          <div className="container-fluid px-md-100">
            <div className="row">
              <div className="col-md-12">
                <h5 className="text-center mb-3">
                  <span className="text-black">Don't have an Account?</span>
                  <Link
                    to={"/register"}
                    className="design-color fw-bold text-decoration-none"
                  >
                    Sign up
                  </Link>
                </h5>

                <div className="text-center">
                  <img src={buttomLine} alt="line" className="line img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
