import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmedDelivery = (props) => {
    const navigate = useNavigate();
    const { onDataHandlerforConfirm, activeSliderConfirmed } = props;
    const [orderStatus, setOrderStatus] = useState([])

    const handleClick = (id) => {
        navigate(`/order-details/${id}`);
    };
    useEffect(() => {
        // let order = onDataHandlerforConfirm?.filter((order) => order.doordash_order_id !== null && order.tracking_url !== null)

        let order = onDataHandlerforConfirm
        setOrderStatus(order)

    }, [onDataHandlerforConfirm])




    return (
        <div className={`tab-pane fade order-size-new ${activeSliderConfirmed === 'Confirmed' ? 'show active' : ''}`} id="confirmed" role="tabpanel" aria-labelledby="confirmed-tab" tabIndex="0">
            {orderStatus?.map((delivery, index) => (
                <div key={index} onClick={() => handleClick(delivery._id)}>
                    <div className="card tab-card mb-4">
                        <div className="card-body p-4 position-relative">
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <p className="mb-1 order-head-color">Order ID</p>
                                    <h6 className="design-color order-text-color">#{delivery.order_id}</h6>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <p className="mb-1 order-head-color">Name</p>
                                    <h6 className="design-color order-text-color">#{delivery.doordash_order_id ? delivery.doordash_order_id : delivery._id}</h6>
                                </div>

                                {/* <div className="col-md-3 col-sm-6 col-xs-12 mt-2">
                                    <p className="mb-1 order-head-color">Mobile Number</p>
                                    <h6 className="design-color order-text-color">{delivery.createdAt}</h6>
                                </div> */}
                                <div className="col-md-4 col-sm-6 col-xs-12 mt-2">
                                    <div className="text-end delivery-process">
                                        <button className="btn btn-delivery process-btn">Process</button>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row mt-2">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                <p className="mb-1 order-head-color">Order Date, Time</p>
                                <h6 className="design-color order-text-color">{delivery.createdAt}</h6>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <p className="mb-1 order-head-color">Products</p>
                                    <h6 className="design-color order-text-color">#{delivery.doordash_order_id ? delivery.doordash_order_id : delivery._id}</h6>
                                </div>

                                {/* <div className="col-md-3 col-sm-6 col-xs-12 mt-2">
                                    <p className="mb-1 order-head-color">Delivery Address</p>
                                    <h6 className="design-color order-text-color">{delivery.createdAt}</h6>
                                </div> */}
                                <div className="col-md-4 col-sm-6 col-xs-12 mt-2">
                                <p className="mb-1 text-end delivery-date">Delivery Date, Time</p>
                                <h6 className="design-color order-text-color">{delivery.order_dropoff_time}</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ConfirmedDelivery;
